import io from "socket.io-client";
let webSocketUrl =
  process.env.REACT_APP_WEBSOCKET_ENDPOINT ||
  "https://onesync-websocket-test-402cf71750fd.herokuapp.com";
// https://onesync-websocket-test-402cf71750fd.herokuapp.com/
// const socket = io("wss://onesyncwebsocket-60652f54c0a6.herokuapp.com");
const socket = io(webSocketUrl);

const connectSocket = () => {
  socket.on("connect", () => {
    // console.log("Connected with socket ID:");
  });

  socket.on("disconnect", () => {
    // console.log("Disconnected from socket");
  });
};

const disconnectSocket = () => {
  socket.disconnect();
};

const onEvent = (event, callback) => {
  socket.on(event, callback);
};

const offEvent = (event, callback) => {
  socket.off(event, callback);
};

export { socket, connectSocket, disconnectSocket, onEvent, offEvent };
