export const LOGOUT = "LOGOUT";

export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";

export const END_AJAX_CALL = "END_AJAX_CALL";

export const BEGIN_FULLSCREEN_AJAX_CALL = "BEGIN_FULLSCREEN_AJAX_CALL";

export const END_FULLSCREEN_AJAX_CALL = "END_FULLSCREEN_AJAX_CALL";

export const PRE_SIGN_IN_SUCCESS = "PRE_SIGN_IN_SUCCESS";

export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";

export const USER_LIST_ALL = "USER_LIST_ALL";

export const ADD_USER = "ADD_USER";

export const USER_BY_ID = "USER_BY_ID";

export const INVENTORY_LIST_ALL = "INVENTORY_LIST_ALL";

export const ADD_TEMPLATE = "ADD_TEMPLATE";

export const TEMPLATE_LIST_ALL = "TEMPLATE_LIST_ALL";

export const TEMPLATE_BY_ID = "TEMPLATE_BY_ID";

export const APPROVAL_LIST_BY_STATUS = "APPROVAL_LIST_BY_STATUS";

export const APPROVAL_LIST_BY_ID = "APPROVAL_LIST_BY_ID";

export const LOCATION_LIST_ALL = "LOCATION_LIST_ALL";

export const PRODUCT_CATALOG_LIST_ALL = "PRODUCT_CATALOG_LIST_ALL";

export const PRODUCT_LANDING_DETAILS_ALL = "PRODUCT_LANDING_DETAILS_ALL";

export const TAG_LIST_ALL = "TAG_LIST_ALL";

export const INVENTORY_STATUS_LIST = "INVENTORY_STATUS_LIST";

export const PAYOUT_DATA = "PAYOUT_DATA";

export const PAYOUT_PENDING_DATA = "PAYOUT_PENDING_DATA";

export const PAYOUT_HISTORY = "PAYOUT_HISTORY";

export const PAYOUT_HISTORY_DETAIL = "PAYOUT_HISTORY_DETAIL";

export const GET_ORDERS_DETAIL = "GET_ORDERS_DETAIL";

export const CONSIGNER_HOME_DATA = "CONSIGNER_HOME_DATA";

export const ROLE_LIST_ALL = "ROLE_LIST_ALL";

export const CONSIGNER_USERS_LIST = "CONSIGNER_USERS_LIST";

export const DYNAMIC_STORE_DOMIAN = "DYNAMIC_STORE_DOMIAN";
