import {
  LOGOUT,
  ADD_USER,
  APPROVAL_LIST_BY_STATUS,
  BEGIN_AJAX_CALL,
  END_AJAX_CALL,
  BEGIN_FULLSCREEN_AJAX_CALL,
  END_FULLSCREEN_AJAX_CALL,
  INVENTORY_LIST_ALL,
  LOCATION_LIST_ALL,
  PRE_SIGN_IN_SUCCESS,
  PRODUCT_CATALOG_LIST_ALL,
  PRODUCT_LANDING_DETAILS_ALL,
  TEMPLATE_BY_ID,
  TEMPLATE_LIST_ALL,
  USER_LIST_ALL,
  TAG_LIST_ALL,
  INVENTORY_STATUS_LIST,
  PAYOUT_DATA,
  PAYOUT_PENDING_DATA,
  PAYOUT_HISTORY,
  PAYOUT_HISTORY_DETAIL,
  GET_ORDERS_DETAIL,
  CONSIGNER_HOME_DATA,
  ROLE_LIST_ALL,
  DYNAMIC_STORE_DOMIAN,
} from "../constants/actionType";

const app = (state = {}, action) => {
  //   console.log("action.type==>", action.type);
  switch (action.type) {
    case BEGIN_AJAX_CALL:
    case END_AJAX_CALL:
      const { showSpinner } = action;
      return {
        ...state,
        showSpinner,
      };
    case BEGIN_FULLSCREEN_AJAX_CALL:
    case END_FULLSCREEN_AJAX_CALL:
      const { showFullSpinner } = action;
      return {
        ...state,
        showFullSpinner,
      };

    case LOGOUT:
      return {};
    case PRE_SIGN_IN_SUCCESS:
      const { preSignInData } = action;
      return {
        ...state,
        preSignInData,
      };
    case USER_LIST_ALL:
      const { userListAll } = action;
      return {
        ...state,
        userListAll,
      };
    case ADD_USER:
      const { addUser } = action;
      return {
        ...state,
        addUser,
      };
    case INVENTORY_LIST_ALL:
      const { inventoryListAll } = action;
      return {
        ...state,
        inventoryListAll,
      };
    case TEMPLATE_LIST_ALL:
      const { templateListAll } = action;
      return {
        ...state,
        templateListAll,
      };
    case TEMPLATE_BY_ID:
      const { templateById } = action;
      return {
        ...state,
        templateById,
      };
    case APPROVAL_LIST_BY_STATUS:
      const { approvalListByStatus } = action;
      return {
        ...state,
        approvalListByStatus,
      };
    case LOCATION_LIST_ALL:
      const { locationListAll } = action;
      return {
        ...state,
        locationListAll,
      };
    case PRODUCT_CATALOG_LIST_ALL:
      const { productCatalogListAll } = action;
      return {
        ...state,
        productCatalogListAll,
      };
    case PRODUCT_LANDING_DETAILS_ALL:
      const { productLandingDetailsAll } = action;
      return {
        ...state,
        productLandingDetailsAll,
      };

    case TAG_LIST_ALL:
      const { tagsList } = action;
      //   console.log("tagsList------------>", tagsList);
      return {
        ...state,
        tagsList,
      };

    case INVENTORY_STATUS_LIST:
      const { invrtyData } = action;
      return {
        ...state,
        invrtyData,
      };

    case PAYOUT_DATA:
      const { payoutdta } = action;
      return {
        ...state,
        payoutdta,
      };

    case PAYOUT_PENDING_DATA:
      const { payoutPending } = action;
      return {
        ...state,
        payoutPending,
      };

    case PAYOUT_HISTORY:
      const { payoutHtry } = action;

      return {
        ...state,
        payoutHtry,
      };
    case GET_ORDERS_DETAIL:
      const { getOrders } = action;

      return {
        ...state,
        getOrders,
      };

    case PAYOUT_HISTORY_DETAIL:
      const { payoutHtrydETAIL } = action;
      return {
        ...state,
        payoutHtrydETAIL,
      };
    case CONSIGNER_HOME_DATA:
      const { consignerHomeData } = action;
      return {
        ...state,
        consignerHomeData,
      };

    case "ManageToggle":
      const { isToggleTrue } = action;
      return {
        ...state,
        isToggleTrue,
      };

    case ROLE_LIST_ALL:
      const { roleListAll } = action;
      return {
        ...state,
        roleListAll,
      };

    case DYNAMIC_STORE_DOMIAN:
      const { storeDomain } = action;

      return {
        ...state,
        storeDomain,
      };

    default:
      return state;
  }
};

export default app;
