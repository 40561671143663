import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Loadable from "react-loadable";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import rootStore from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { store } = rootStore;

const root = ReactDOM.createRoot(document.getElementById("root"));
Loadable.preloadReady().then(() => {
  root.render(
    // <React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path={"*"} element={<App />} />
          {/* <Route path="/onesync/*" element={<Theme blue10={""} />} /> */}
        </Routes>
      </Router>
    </Provider>
    //  </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
