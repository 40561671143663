const routes = {
  mainRoute: JSON.parse(localStorage.getItem("storeDeatils"))?.domain,
  preSignIn: "signin",
  signIn: "store",
  // forgotPassword: 'forgot-password',
  // resetPassword: 'reset-password',
  // notAuthorize: 'not-authorize',
  containers: {
    home: "home",
    inventory: "inventory",
    deliveries: "deliveries",
    payouts: "payouts",
    orders: "orders",
    settings: "settings",
    products: "products",
    users: "users",
  },
  inventory: {
    inventory: {
      name: "inventory",
      add: "add-inventory",
      edit: "edit-inventory",
      view: "view-inventory",
    },
  },
  consigner: {
    home: "consigner",
  },
  superAdmin: {
    home: "super-admin",
  },
};

export default routes;
